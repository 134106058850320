<template>
  <div>
    <PagesPath path-title1="کد رهگیری پستی" :pathTo1="$route.path" />
    <section class="bg-white box-shaddow20 margin-t-100 post-tracking">
      <div class="container mb-container text-color-white border-r-20 title-post-tracking">
        <div class="title-post-content">
        <h2>
          کد رهگیری پست
        </h2>
        <p class="mb-0 title-post-tracking-p1">
          کد های رهگیری تمامی مرسوله ها روزانه در این صفحه آپدیت می شود. <br>
          شما می توانید کد رهگیری مرسوله خود را با جستوجوی اسم و فامیلی خود بیابید و در سامانه رهگیری پست پیگیری نمایید.

        </p>
      </div>
      </div>
      <div class="container mb-container box-shaddow20 padding-r-20 border-r-30 padding-14 mt-4 bg-color-brown text-color-white d-flex align-items-center justify-content-between">
        <a href="https://tracking.post.ir/" class="text-color-white font-bold">
          <strong>
          برای رفتن به سامانه رهگیری ملی پست اینجا کلیک کنید

          </strong>
        </a>
        <span>
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.36523 9.71716H15.2402" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.15482 14.4865L3.36523 9.71746L8.15482 4.94766" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            
        </span>
      </div>
      <div class="container mb-container box-shaddow20 padding-r-20 border-r-30 padding-14 mt-4 bg-color-theme5 d-flex align-items-center gap-8" style="flex-wrap: wrap;color:#5F0000 ;">
        <div class="d-flex align-items-center gap-4" >
          <span>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.2835 5.58447L11.3658 2.74481C10.5628 2.28119 9.56932 2.28119 8.75799 2.74481L3.84861 5.58447C3.04556 6.04808 2.54883 6.90909 2.54883 7.8446V13.5074C2.54883 14.4346 3.04556 15.2956 3.84861 15.7675L8.76627 18.6072C9.56932 19.0708 10.5628 19.0708 11.3741 18.6072L16.2918 15.7675C17.0948 15.3039 17.5916 14.4429 17.5916 13.5074V7.8446C17.5833 6.90909 17.0866 6.05636 16.2835 5.58447ZM9.44514 7.15745C9.44514 6.81802 9.72662 6.53654 10.0661 6.53654C10.4055 6.53654 10.687 6.81802 10.687 7.15745V11.5039C10.687 11.8433 10.4055 12.1248 10.0661 12.1248C9.72662 12.1248 9.44514 11.8433 9.44514 11.5039V7.15745ZM10.8277 14.5091C10.7863 14.6085 10.7284 14.6995 10.6539 14.7823C10.4966 14.9396 10.2896 15.0224 10.0661 15.0224C9.95843 15.0224 9.85081 14.9976 9.75146 14.9562C9.64383 14.9148 9.56104 14.8568 9.47826 14.7823C9.40375 14.6995 9.34579 14.6085 9.29612 14.5091C9.25473 14.4098 9.23817 14.3021 9.23817 14.1945C9.23817 13.9793 9.32096 13.764 9.47826 13.6067C9.56104 13.5322 9.64383 13.4742 9.75146 13.4328C10.0578 13.3004 10.422 13.3749 10.6539 13.6067C10.7284 13.6895 10.7863 13.7723 10.8277 13.8799C10.8691 13.9793 10.8939 14.0869 10.8939 14.1945C10.8939 14.3021 10.8691 14.4098 10.8277 14.5091Z" fill="#FF3541"/>
              </svg>
              
          </span>
          <p class="mb-0 " style="color: #FF3541; font-size: 17px;">
            توجه:
          </p>
        </div>
        <div class="meassge-post-tracking d-flex align-items-center gap-8" style="font-size: 14px;">
          <p class="mb-0">
            لطفا توجه داشته باشید از تاریخ ثبت سفارش شما تا آپلود کد رهگیری شما حداقل 4 و حداکثر 7 روز کاری زمان میبرد
          </p>
          <span class="line">
            <!-- <svg width="2" height="16" viewBox="0 0 2 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.32 11.45V0.815H1.145V11.45H0.32Z" fill="black"/>
              </svg>
               -->
          </span>
          <p class="mb-0">
            (چنانچه بعد از 8 روز کاری نام شما در لیست کدهای رهگیری نبود ، لطفا با شماره <strong class="font-bold " style="font-weight: 900;">01732549221</strong> تماس بگیرید)
          </p>
        </div>
      </div>
    <div class="container mb-container box-shaddow20 padding-r-20 border-r-30  mt-4 pr-0 pl-0">
  
      <!-- <div v-if="settings" v-html="settings.site.post_tracking"></div> -->
      <div class="w-100 mb-4 d-flex box-form-control align-items-center justify-content-between">
        <span>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0007 1.83334C8.59898 1.83334 6.64648 3.78584 6.64648 6.18751C6.64648 8.54334 8.48898 10.45 10.8907 10.5325C10.964 10.5233 11.0373 10.5233 11.0923 10.5325C11.1107 10.5325 11.1198 10.5325 11.1382 10.5325C11.1473 10.5325 11.1473 10.5325 11.1565 10.5325C13.5032 10.45 15.3457 8.54334 15.3548 6.18751C15.3548 3.78584 13.4023 1.83334 11.0007 1.83334Z" fill="#636363"/>
            <path d="M15.6569 12.9708C13.0994 11.2658 8.92854 11.2658 6.35271 12.9708C5.18854 13.75 4.54688 14.8042 4.54688 15.9317C4.54688 17.0592 5.18854 18.1042 6.34354 18.8742C7.62688 19.7358 9.31354 20.1667 11.0002 20.1667C12.6869 20.1667 14.3735 19.7358 15.6569 18.8742C16.8119 18.095 17.4535 17.05 17.4535 15.9133C17.4444 14.7858 16.8119 13.7408 15.6569 12.9708Z" fill="#636363"/>
            </svg>
            
        </span>
        <input
          placeholder="نام خود را وارد کنید"
          type="text"
          class="form-control"
          v-model="search"
        />
        <span>
          <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="19.5" cy="19.5" r="19.5" fill="#414141"/>
            <path d="M19.4319 24.0653C18.8879 24.6094 18.0051 24.6094 17.4613 24.0653L14.408 21.012C13.864 20.4682 13.864 19.5854 14.408 19.0416C14.9518 18.4975 15.8346 18.4975 16.3787 19.0416L18.1978 20.8605C18.3351 20.9975 18.5581 20.9975 18.6957 20.8605L23.6213 15.9347C24.1651 15.3906 25.0479 15.3906 25.592 15.9347C25.8532 16.196 26 16.5504 26 16.9199C26 17.2894 25.8532 17.6438 25.592 17.9051L19.4319 24.0653Z" fill="white"/>
            </svg>
            
        </span>
      </div>
      <b-table
        v-if="search"
        responsive
        :filter-included-fields="['receiver_name']"
        :filter="search"
        :fields="fields"
        :items="items"
      >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(price)="data">
          {{ data.item.price | price }}
        </template>
      </b-table>
    </div>
  </section>
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import PagesPath from "@/parts/Front/components/PagesPath";

export default {
  name: "PostTracking",
  components: {
    BTable,PagesPath
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "receiver_name",
          label: "نام گیرنده",
        },
        {
          key: "barcode",
          label: "کد رهگیری پست",
        },
        {
          key: "register_date",
          label: "تاریخ ثبت",
        },
        {
          key: "destination",
          label: "مقصد",
        },
      ],
      search: null,
      listSearch: [],
    };
  },
  mounted() {
  },
  watch: {
    search(newValue) {
      this.listSearch.push(newValue);
      this.searchInExcel(this.listSearch.length);
    },
  },
  methods: {
    searchInExcel(len) {
      setTimeout(() => {
        if (!this.listSearch[len]) {
         this.$axios
            .get(`front/shipping_excels?keyword=${this.listSearch[len-1]}`)
            .then((res) => {
              this.items = res.data.data.shipping_excels;
            })
            .catch((error) => {
              alert("مشکلی رخ داد");
            });
        }
      }, 1000);
    },
  },
  computed: {
    settings() {
      const homeData = this.$store.getters["front/getHomeData"];

      return homeData ? homeData.settings : null;
    },
  },
};
</script>

<style scoped>
.title-post-tracking{
  background-image: url("../../../assets/images/bgpost.png") ; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 position: relative;
  padding: 34px 20px;
height: 172px;
top: -87px;
margin-bottom: -81px;
}
.margin-t-100{
  margin-top: 100px;
}

.title-post-tracking::after{
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f172abcf;
  border-radius: 20px;
  
  
}
.title-post-content{
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);

}
.title-post-tracking h2{
  font-weight: 900;
  font-size: 32px;
  

  
}
.padding-r-30{
  padding-right: 31px;
}
.title-post-tracking p{
  font-weight: 400;
  font-size: 14px;
line-height: 2;
}

.form-control{
  border: unset;
  outline: unset !important;
}
.form-control:focus{
  border: unset !important;
  box-shadow:unset !important;

}
.box-form-control{
  border: 1px solid #ced4da;
  padding:6px 9px 6px 4px;
  border-radius: 30px;

}
.form-control::placeholder{
  text-align: center;
}
.post-tracking .line{
  width: 2px;
  height: 16px;
  background-color: #000;
}
@media screen and (max-width: 1335px) {
  
  .post-tracking .line{
    height: 42px;

  }
  
}
@media screen and (max-width: 768px) {
  
  .post-tracking .line{
    display: none;
  }
  .meassge-post-tracking{
    flex-wrap: wrap;
  }
  .meassge-post-tracking p:nth-child(1){
    border-bottom: 1px solid #5F000025;
    padding-bottom: 8px;
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 568px) {
  
 .mb-container{
  width: 90%;
  margin-right: auto;
  margin-left: auto;
 }
}
</style>
